import React from "react";
import { SmallText } from "./ui-components";
import { Link } from "gatsby";
import styled from "styled-components";

const YearsList = styled.ul`
  list-style: none;
  margin: 0;

  li,
  p {
    margin: 0;
    display: inline;
  }

  li {
    margin-right: 0.75rem;
    position: relative;

    &.arrow {
      margin-right: 1.5rem;

      &:after {
        content: "";
        position: absolute;
        border: solid rgb(3, 86, 66);
        border-width: 0 1px 1px 0;
        padding: 3px;

        top: 50%;
        right: -0.75rem;

        transform: rotate(-45deg);
        -webkit-transform: rotate(-45deg);
      }
    }
  }
`;

export default function ArchivePickerIndex({ postsYears }) {
  const formattedPostsYears = postsYears.map((year) => {
    return (
      <li key={year}>
        <SmallText>
          <Link to={`/archive/${year}/`}>{year}</Link>
        </SmallText>
      </li>
    );
  });

  return (
    <div>
      <YearsList>
        <li key={"all"} className={"arrow"}>
          <SmallText>
            <Link to="/archive/">All</Link>
          </SmallText>
        </li>
        {formattedPostsYears}
      </YearsList>
    </div>
  );
}
