import React, { useState } from "react";
import { graphql, Link } from "gatsby";
import { mapEdgesToNodes, filterUniqueTags } from "../lib/helpers";
import SEO from "../components/seo";
import ArchivePickerIndex from "../components/ArchivePickerIndex";
import Sidebar from "../components/Sidebar";
import Layout from "../containers/layout";
import PostSorter from "../components/PostSorter";
import SingleRowVertical from "../components/post-previews/SingleRowVertical";
import {
  PageTitle,
  PageDivider,
  MaxWidth,
  FlexRow,
  Col66,
  Col33,
  SmallDivider,
  SmallText,
  PickerSorterWrapper,
} from "../components/ui-components";

export const query = graphql`
  query ArchivePageQuery {
    newestPosts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: 10
    ) {
      edges {
        node {
          id
          publishedAt(formatString: "MMM DD, YYYY")
          mainImage {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              id
              name
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          tags {
            tagName
            slug {
              current
            }
          }
          slug {
            current
          }
        }
      }
    }
    oldestPosts: allSanityPost(
      sort: { fields: [publishedAt], order: ASC }
      filter: { slug: { current: { ne: null } }, publishedAt: { ne: null } }
      limit: 10
    ) {
      edges {
        node {
          id
          publishedAt(formatString: "MMM DD, YYYY")
          mainImage {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }
          authors {
            author {
              id
              name
              image {
                asset {
                  fixed(width: 36) {
                    ...GatsbySanityImageFixed_noBase64
                  }
                }
              }
              slug {
                current
              }
            }
          }
          title
          _rawExcerpt(resolveReferences: { maxDepth: 5 })
          _rawBody(resolveReferences: { maxDepth: 5 })
          tags {
            tagName
            slug {
              current
            }
          }
          slug {
            current
          }
        }
      }
    }
    postsYears: allSanityPost(filter: { publishedAt: { ne: null } }) {
      edges {
        node {
          publishedAt(formatString: "YYYY")
        }
      }
    }
    allSanitySiteSettings {
      nodes {
        contactInfo {
          email
          facebook
          instagram
          twitter
        }
        description
        title
      }
    }
    allSanityAuthor {
      nodes {
        image {
          asset {
            fixed(width: 40) {
              ...GatsbySanityImageFixed
            }
          }
        }
        id
        name
        slug {
          current
        }
      }
    }
    allSanityBlogPostTag {
      nodes {
        tagName
        slug {
          current
        }
        id
      }
    }
  }
`;

const ArchivePage = (props) => {
  const [sortByNewest, setSortByNewest] = useState(true);

  const handleOnChange = (sortByNewest) => {
    setSortByNewest(sortByNewest);
  };

  const { data, location } = props;

  const newestPostNodes = data && data.newestPosts && mapEdgesToNodes(data.newestPosts);

  const NewestPosts = (
    <SingleRowVertical
      posts={newestPostNodes}
      authorTop={true}
      readMoreLink={true}
      className={"border-bottom"}
    />
  );

  const oldestPostNodes = data && data.oldestPosts && mapEdgesToNodes(data.oldestPosts);
  const OldestPosts = (
    <SingleRowVertical
      posts={oldestPostNodes}
      authorTop={true}
      readMoreLink={true}
      className={"border-bottom"}
    />
  );

  const postsYears =
    data &&
    data.postsYears &&
    mapEdgesToNodes(data.postsYears)
      .map((edge) => parseInt(edge.publishedAt))
      .sort();

  const uniquePostsYears = [...new Set(postsYears)];

  let yearsArray = [];

  for (let index = 0; index < uniquePostsYears.length; index++) {
    const year = uniquePostsYears[index];

    if (index !== uniquePostsYears.length - 1) {
      yearsArray = [
        ...yearsArray,
        <Link key={year} to={`/archive/${year}/`}>
          {year}
        </Link>,
        ", ",
      ];
    } else {
      yearsArray = [
        ...yearsArray,
        "and ",
        <Link key={year} to={`/archive/${year}/`}>
          {year}
        </Link>,
      ];
    }
  }

  const allPostNodes = [...newestPostNodes, ...oldestPostNodes];
  const tags = filterUniqueTags(allPostNodes);

  return (
    <Layout location={location.pathname}>
      <SEO title="Archive" />
      <>
        <MaxWidth>
          <PageTitle>Archive</PageTitle>
        </MaxWidth>
        <PageDivider />
        <MaxWidth>
          <FlexRow>
            <Col66>
              <p style={{ marginBottom: 0, color: "0,0,0,0.84" }}>
                Archive of stories published by Inside Cal Poly
              </p>

              <SmallDivider />

              <PickerSorterWrapper>
                <ArchivePickerIndex postsYears={uniquePostsYears} />
                <PostSorter sortByNewest={sortByNewest} handleOnChange={handleOnChange} />
              </PickerSorterWrapper>

              <SmallDivider />

              {sortByNewest ? NewestPosts : OldestPosts}

              <SmallText>
                These were the top stories published by Inside Cal Poly; you can also dive into
                yearly archives: {yearsArray}.
              </SmallText>
            </Col66>

            <Col33>
              <Sidebar
                settings={data.allSanitySiteSettings.nodes[0]}
                tags={tags}
                authors={data.allSanityAuthor.nodes}
              />
            </Col33>
          </FlexRow>
        </MaxWidth>
      </>
    </Layout>
  );
};

export default ArchivePage;
